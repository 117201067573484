<template>
  <q-layout class="bg-grey-1" view="hHh lpR fff">
    <q-header elevated class="text-white" height-hint="61.59">
      <q-toolbar class="q-py-sm q-px-md">
        <q-btn round dense flat :ripple="false" :href="link_portal" size="15px" color="white" class="q-mr-sm" no-caps>
          <q-img
            src="~assets/logo/logo.svg"
            fit="contain"
            style="margin: 2px"
          />
        </q-btn>

        <div v-if="$q.screen.gt.sm" class="GL__toolbar-link q-ml-xs q-gutter-md text-body2 text-weight-bold row items-center no-wrap">
          <router-link :to="{name: 'Home'}" class="text-white">
            {{ $t('menu.home') }}
          </router-link>
          <router-link :to="{name: 'Events'}" class="text-white" v-if="userType && ['client', 'prestataire', 'admin', 'super-admin'].includes(userType)">
            {{ $t('menu.events') }}
          </router-link>
          <router-link :to="{name: 'Challenges'}" class="text-white" v-if="userType && ['admin', 'super-admin', 'prestataire', 'client', 'user'].includes(userType)">
            {{ $t('menu.challenges') }}
          </router-link>
          <a :href="link_result" class="text-white" v-if="userType && ['user'].includes(userType)">
            {{ $t('menu.results') }}
          </a>
          <a :href="link_billing" target="_blank" class="text-white" v-if="userType && ['client'].includes(userType)">
            {{ $t('menu.billing') }} <q-badge color="red" rounded :label="unpaidInvoice" v-if="unpaidInvoice > 0" />
          </a>
          <router-link :to="{name: 'AdminOrganizerList'}" class="text-white" v-if="userType && ['admin', 'super-admin'].includes(userType)">
            {{ $t('menu.organizers') }}
          </router-link>
          <router-link :to="{name: 'AdminPrestataireList'}" class="text-white" v-if="userType && ['admin', 'super-admin'].includes(userType)">
            {{ $t('menu.prestataires') }}
          </router-link>
          <router-link :to="{name: 'AdminRiderList'}" class="text-white" v-if="userType && ['admin', 'super-admin'].includes(userType)">
            {{ $t('menu.riders') }}
          </router-link>
          <router-link :to="{name: 'StatsBilling'}" class="text-white" v-if="userType && ['admin', 'super-admin'].includes(userType)">
            {{ $t('menu.stats') }}
          </router-link>
          <router-link :to="{name: 'StatsMap'}" class="text-white" v-if="userType && ['admin', 'super-admin'].includes(userType)">
            {{ $t('menu.map') }}
          </router-link>
          <router-link :to="{name: 'StatsStaffing'}" class="text-white" v-if="userType && ['admin', 'super-admin'].includes(userType)">
            {{ $t('menu.staffing') }}
          </router-link>
        </div>

        <q-space />

        <div class="q-pl-sm q-gutter-sm row items-center no-wrap">
          <q-badge color="grey">v6.0.{{ releaseVersion }}</q-badge>

          <q-btn dense flat no-wrap>
            <q-avatar color="white" text-color="primary" icon="fa-solid fa-user" size="sm" v-if="userNameAvatar === ''" font-size="10px" />
            <q-avatar color="white" text-color="primary" size="sm" v-if="userNameAvatar !== ''">{{ userNameAvatar }}</q-avatar>
            <q-icon name="fa-solid fa-caret-down" size="16px" />

            <q-menu auto-close>
              <q-list dense>
                <q-item class="GL__menu-link-signed-in" v-if="userName !== ''">
                  <q-item-section>
                    <div><strong>{{ userName }}</strong></div>
                    <div>{{ userLogin }}</div>
                  </q-item-section>
                </q-item>
                <q-separator v-if="userName !== ''" />
                <q-item clickable class="GL__menu-link-status">
                  <q-item-section>
                    <div>
                      {{ userTypeLabel }}
                    </div>
                  </q-item-section>
                </q-item>
                <q-separator />
                <q-item clickable class="GL__menu-link" v-if="userType && userType === 'client'" :to="{name: 'UserEntity'}">
                  <q-item-section>{{ entityName }}</q-item-section>
                </q-item>
                <q-item clickable class="GL__menu-link" :to="{name: 'Home'}">
                  <q-item-section :to="{name: 'UserMe'}">{{ $t('menu.home' )}}</q-item-section>
                </q-item>
                <q-item clickable class="GL__menu-link" :to="{name: 'UserMe'}">
                  <q-item-section :to="{name: 'UserMe'}">{{ $t('menu.account' )}}</q-item-section>
                </q-item>
                <q-item clickable class="GL__menu-link" :to="{name: 'Events'}" v-if="userType && ['client', 'admin', 'super-admin', 'prestataire'].includes(userType)">
                  <q-item-section>{{ $t('menu.events' ) }}</q-item-section>
                </q-item>
                <q-item clickable class="GL__menu-link" :href="link_result" v-if="userType && ['user'].includes(userType)">
                  <q-item-section>{{ $t('menu.results' ) }}</q-item-section>
                </q-item>

                <q-item clickable class="GL__menu-link" :to="{name: 'Challenges'}" v-if="userType && ['admin', 'super-admin', 'prestataire', 'client', 'user'].includes(userType)">
                  <q-item-section>{{ $t('menu.challenges' ) }}</q-item-section>
                </q-item>
                <q-item clickable class="GL__menu-link" :href="link_billing" target="_blank" v-if="userType && userType === 'client'">
                  <q-item-section>{{ $t('menu.billing' ) }}</q-item-section>
                </q-item>
                <q-item clickable class="GL__menu-link" :to="{name: 'AdminOrganizerList'}" v-if="userType && ['admin', 'super-admin'].includes(userType)">
                  <q-item-section>{{ $t('menu.organizers' ) }}</q-item-section>
                </q-item>
                <q-item clickable class="GL__menu-link" :to="{name: 'AdminPrestataireList'}" v-if="userType && ['admin', 'super-admin'].includes(userType)">
                  <q-item-section>{{ $t('menu.prestataires' ) }}</q-item-section>
                </q-item>
                <q-item clickable class="GL__menu-link" :to="{name: 'AdminRiderList'}" v-if="userType && ['admin', 'super-admin'].includes(userType)">
                  <q-item-section>{{ $t('menu.riders' ) }}</q-item-section>
                </q-item>
                <q-separator />
                <q-item clickable class="GL__menu-link" :href="link_support_documentation" target="_blank">
                  <q-item-section>{{$t('menu.help' ) }}</q-item-section>
                </q-item>
                <q-item clickable @click="userLogout()" class="GL__menu-link">
                  <q-item-section>{{ $t('menu.logout') }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </q-toolbar>
        <q-banner class="bg-red text-white text-center" v-if="unpaidInvoice > 0">
          Vous avez <strong>{{unpaidInvoice}} factures en retard de paiement</strong>, merci de régulariser votre compte rapidement.
        </q-banner>
    </q-header>

    <q-page-container>
      <router-view v-if="user" />
    </q-page-container>
    <q-footer class="text-center bg-transparent">
      <iframe src="https://status.horses-and-competitions.com/badge?theme=light" width="250" height="30" frameborder="0" scrolling="no"></iframe>
    </q-footer>
  </q-layout>
</template>

<script>
import { ref } from 'vue'
import { Cookies, useMeta, Loading } from 'quasar'
import { useStore } from 'vuex'
import {Crisp} from "crisp-sdk-web";

const metaData = {
  // sets document title
  title: 'Accueil',
  // optional; sets final title as "Index Page - My Website", useful for multiple level meta
  titleTemplate: title => `${title} - Horses & Competitions`,
}

export default {
  name: 'MainLayout',
  data: () => ({
    link_portal: process.env.VUE_APP_LINK_PORTAL,
    link_result: process.env.VUE_APP_LINK_RESULT_DASHBOARD,
    link_support_status: process.env.VUE_APP_LINK_SUPPORT_STATUS,
    link_support_documentation: process.env.VUE_APP_LINK_SUPPORT_DOCUMENTATION,
    link_billing: process.env.VUE_APP_LINK_BILLING,
    user: null
  }),
  setup () {
    // set metadata
    useMeta(metaData)

    // display global loader
    Loading.show({
      message: 'Chargement...'
    })

    return {
    }
  },
  created() {
    // force lang for BO user
    this.$i18n.locale = 'fr-FR'
  },
  mounted: function () {

    // redirect user to live result if custom domain
    let actual_host = window.location.protocol + "//" + window.location.host
    if (process.env.VUE_APP_URL !== actual_host){
      window.location.href = actual_host + '/live/';
    }


    const $store = useStore()

    // load userState
    $store.dispatch("user/getUser").then(() => {
      Loading.hide()
      // set crisp
      let user = $store.getters["user/userGetter"]

      if (user != null ) {
        this.user = user
        Crisp.setTokenId(user.crisp.token_id);

        if (user.email != '')
          Crisp.user.setEmail(user.email);

        if (user.type === 'client')
          Crisp.user.setCompany(user.entity.name);
        else
          Crisp.user.setCompany('None');

        Crisp.user.setNickname(user.firstname + ' ' + user.lastname);

        Crisp.session.setData({
          user_id : user.id.toString(),
          code_ffe : user.login,
          role : (user.type === 'client' ? 'organisateur' : user.type)
        });
      }
      Crisp.load()
    })
  },
  methods: {
    userLogout: async function () {
      this.$q.loading.show({
        message: 'Déconnexion en cours...'
      })
      Crisp.session.reset();
      this.$store.dispatch("user/logout").then(() => {
        window.location.href = process.env.VUE_APP_LINK_LOGOUT
      })
    }
  },
  computed: {
    userName() {
      let user = this.$store.getters["user/userGetter"]

      if (user) {
        if (user.firstname.length > 0 && user.lastname.length > 0)
          return user.firstname + ' ' + user.lastname
      }

      return ''
    },
    userLogin() {
      let user = this.$store.getters["user/userGetter"]

      if (user) {
        if (user.login)
          return user.login
      }

      return ''
    },
    releaseVersion() {
      return parseInt(process.env.VUE_APP_AWS_JOB_ID, 10);
    },
    userTypeLabel() {
      if (this.$store.getters["user/userGetter"]) {
        switch (this.$store.getters["user/userGetter"].type) {
          case 'user':
            return this.$i18n.t('user.type.rider')
          case 'client':
            return this.$i18n.t('user.type.organizer')
          case 'super-admin':
            return this.$i18n.t('user.type.admin')
          case 'prestataire':
            return this.$i18n.t('user.type.prestataire')
          default:
            return this.$i18n.t('user.type.unknown')
        }
      }

      return ''
    },
    userType() {
      if (this.$store.getters["user/userGetter"]) {
        return this.$store.getters["user/userGetter"].type
      }

      return null
    },
    userNameAvatar() {
      let user = this.$store.getters["user/userGetter"]

      if (user) {
        if (user.firstname.length > 0 && user.lastname.length > 0)
          return user.firstname.charAt(0) + user.lastname.charAt(0)
      }

      return ''
    },
    entityName() {
      let user = this.$store.getters["user/userGetter"]

      if (user && user.type === 'client') {
        return user.entity.name
      }

      return ''
    },
    unpaidInvoice() {
      let user = this.$store.getters["user/userGetter"]

      if (user && user.type === 'client') {
        return user.invoices.unpaid
      }

      return 0
    },
  }
}
</script>

<style lang="sass">
.GL
  &__select-GL__menu-link
    .default-type
      visibility: hidden

    &:hover
      background: #121d4f
      color: white
      .q-item__section--side
        color: white
      .default-type
        visibility: visible

  &__toolbar-link
    a
      color: white
      text-decoration: none
      &:hover
        opacity: 0.7

  &__menu-link:hover
    background: #ececec

  &__menu-link-signed-in,
  &__menu-link-status
    &:hover
      & > div
        background: white !important

  &__menu-link-status
    color: $blue-grey-6
    &:hover
      color: #121d4f

  &__toolbar-select.q-field--focused
    width: 450px !important
    .q-field__append
      display: none
</style>
